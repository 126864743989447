/* eslint-disable max-lines */
import { Query, Mutation } from '@apollo/client/react/components';
import {
  Classes,
  Alignment,
  H3,
  Card,
  NavbarGroup,
  Button,
  Icon,
  Collapse,
  Tooltip,
  Tag,
  Elevation,
} from '@blueprintjs/core';
import _, { capitalize } from 'lodash';
import moment from 'moment-timezone';
import { Component, Fragment } from 'react';
import { useParams } from 'react-router';

import adminLoginAsUser from '@graphql/mutations/user/adminLoginAsUser';
import updateUser from '@graphql/mutations/user/updateUser';
import getParticipantDetails from '@graphql/queries/user/getParticipantDetails';
import { PageTitle } from 'components/PageTitle';
import {
  AppConsumer,
  AskableNavbar,
  ErrorCallout,
  SkeletonWrapper,
  // Divider,
  AudioButton,
  Emoji,
} from 'components/common';
import { utils } from 'lib/utils';

import ParticipantScreenerAnalysis from './ScreenerAnalysis/ScreenerAnalysisContainer';
import BookingsTable from './user.participant-table-bookings';
import PaymentsTable from './user.participant-table-payments';
import AppFrame from './user.participant.app.view';
import AdminMessages from './user.participant.info-admin-messages.view';
import InfoOptions from './user.participant.info-options.view';
import UpdateProfile from './user.participant.info-profile.view';

import type {
  Admin_GetParticipantDetailsQuery,
  Admin_UpdateUserMutation,
  Admin_UpdateUserMutationVariables,
} from 'generated/graphql';
import type { FC } from 'react';

const errorPage = ({ error, title }: any) => (
  <div className="content-page">
    <ErrorCallout error={error} title={title} />
  </div>
);

const makeDDLink = (userId: string) => () => {
  window.open(
    `https://us3.datadoghq.com/dashboard/z8g-55c-f55/participant-usage-dashboard?tpl_var_user.userId%5B0%5D=${userId}`,
    '_blank',
    'noopener noreferrer',
  );
};

const makeDDPaypalLink = (userId: string) => () => {
  window.open(
    `https://us3.datadoghq.com/dashboard/wye-iab-m7x?tpl_var_user.userId%5B0%5D=${userId}`,
    '_blank',
    'noopener noreferrer',
  );
};

interface Props {
  _user_id?: string;
  context: {
    newToast: (config: { message: string; intent: string }) => void;
  };
}

interface State {
  participantAppPath: string;
  tabs_info: string;
  tabs_table: string;
  infoCardCollapsed: boolean;
  timezone?: string;
  user?: Admin_GetParticipantDetailsQuery['userByID'];
}

class ParticipantDetails extends Component<Props, State> {
  tabs: any;

  constructor(props: any) {
    super(props);

    this.tabs = {
      info: [
        'Admin Messages',
        'Update Profile',
        'Options',
        // 'Log',
      ],
      table: ['Bookings', 'Payments', 'Screener analysis', 'Access logs', 'Paypal logs'],
    };

    this.state = {
      participantAppPath: '/',
      tabs_info: this.tabs.info[0],
      tabs_table: this.tabs.table[0],
      infoCardCollapsed: window.innerHeight < 900,
    };

    this.onClickTab = this.onClickTab.bind(this);
    this.toggleTimezone = this.toggleTimezone.bind(this);
    this.userQueryCompletedCallback = this.userQueryCompletedCallback.bind(this);
    this.participantAppNavigate = this.participantAppNavigate.bind(this);
    this.participantAppLinkClick = this.participantAppLinkClick.bind(this);
    this.parseTabs = this.parseTabs.bind(this);
    this.renderInfoCard = this.renderInfoCard.bind(this);
    this.renderInfoSection = this.renderInfoSection.bind(this);
    this.renderTablesSection = this.renderTablesSection.bind(this);
  }

  onClickTab({ nav, item }: any) {
    this.setState(state => _.set(state, `tabs_${nav}`, item));
  }

  toggleTimezone() {
    if (!this.state.user) {
      console.warn('No user data in state');
      return;
    }
    let timezone: string | undefined;
    if (this.state.timezone === this.state.user?.location?.timezone) {
      timezone = moment.tz.guess();
    } else {
      timezone = this.state.user?.location?.timezone ?? undefined;
    }
    this.setState({ timezone });
    moment.tz.setDefault(timezone);
  }

  userQueryCompletedCallback({ userByID }: Admin_GetParticipantDetailsQuery) {
    if (!this.state.timezone && userByID?.location?.timezone) {
      this.setState({ timezone: userByID.location.timezone });
      moment.tz.setDefault(userByID.location.timezone);
    }
    if (userByID?._id && _.get(this.state, 'user._id') !== userByID?._id) {
      this.setState({ user: userByID });
    }
  }

  participantAppLinkClick(event: any) {
    event.preventDefault();
    if (event?.target?.attributes?.href?.value) {
      this.participantAppNavigate({ path: event.target.attributes.href.value, scroll: true });
    }
  }

  participantAppNavigate({ path, scroll }: any) {
    if (scroll) {
      document.getElementById('user-participant-details')?.scrollTo({
        left: document.getElementById('user-participant-details')?.clientWidth,
        behavior: 'smooth',
      });
    }
    this.setState({ participantAppPath: path });
  }

  parseTabs(nav: 'info' | 'table') {
    return this.tabs[nav].map((item: any) => {
      if (item === 'Access logs') {
        return {
          title: item,
          active: false,
          rightIcon: 'share',
          onClick: makeDDLink(this.state.user?._id || ''),
        };
      }
      if (item === 'Paypal logs') {
        return {
          title: item,
          active: false,
          rightIcon: 'share',
          onClick: makeDDPaypalLink(this.state.user?._id || ''),
        };
      }
      return {
        title: item,
        active: this.state[`tabs_${nav}`] === item,
        onClick: () => {
          this.onClickTab({ nav, item });
        },
      };
    });
  }

  renderInfoCard({ user, loading }: any) {
    const details = {
      name: `${_.get(user, 'meta.identity.firstname') || ''} ${_.get(user, 'meta.identity.lastname') || ''}`.replace(
        /^\s+|\s+$/g,
        '',
      ),
      locality: loading ? '...' : _.get(user, 'location.city'),
      region: loading
        ? '...'
        : [_.get(user, 'location.region'), _.get(user, 'location.state'), _.get(user, 'location.country')]
            .filter(v => v)
            .join(', '),
      rating: {
        overall: user?.rating?.score,
        bookings: user?.rating?.ratings,
      },
      earnings: {
        sum: _.sumBy(user.ParticipantIncentives || [], 'total_amount'),
        count: (user.ParticipantIncentives || []).length,
      },
    };

    return (
      <Card
        elevation={Elevation.ONE}
        className={`margin-4 margin-left-3 margin-bottom-${this.state.infoCardCollapsed ? '1' : '3'}`}
      >
        <H3 className="flex flex-align-baseline">
          {!loading && user.blacklist && <Emoji className="margin-right-1" emoji="☠️" label="Blacklisted" />}

          {!loading && !user.status && <Emoji className="margin-right-1" emoji="🚫" label="Inactive" />}

          <SkeletonWrapper active={loading} length={10}>
            {details.name || '(No name)'}
          </SkeletonWrapper>
          <Button
            minimal
            icon={this.state.infoCardCollapsed ? 'chevron-left' : 'chevron-down'}
            className="margin-left-auto"
            onClick={() => {
              this.setState({ infoCardCollapsed: !this.state.infoCardCollapsed });
            }}
          />
        </H3>
        <Collapse isOpen={!this.state.infoCardCollapsed} className="margin-bottom--1">
          <div className="flex flex-row margin-bottom-2 flex-wrap">
            <SkeletonWrapper active={loading} length={4} className="margin-right-2">
              {_.chain(user).get('meta.identity.gender').capitalize().value()}
            </SkeletonWrapper>
            <SkeletonWrapper active={loading} length={2} className="margin-right-3">
              {_.get(user, 'meta.identity.birthday.timestamp') && (
                <Fragment>
                  {moment(user.meta.identity.birthday.timestamp).format('DD-MM') === moment().format('DD-MM') && (
                    <Emoji emoji="🎉" label="Happy birthday" className="margin-right-05" />
                  )}
                  {moment().diff(moment(user.meta.identity.birthday.timestamp).utc(), 'years')}
                </Fragment>
              )}
            </SkeletonWrapper>
            <SkeletonWrapper active={loading} length={4} className="margin-right-4">
              <Tooltip
                content={
                  <Fragment>
                    <div>
                      from {details.rating.bookings} booking
                      {details.rating.bookings === 1 ? '' : 's'}
                    </div>
                  </Fragment>
                }
              >
                <span>
                  <Emoji emoji="⭐️" label="Star rating" />
                  &nbsp;&nbsp;{details.rating.overall !== undefined ? (details.rating.overall * 5).toFixed(2) : '?'}
                </span>
              </Tooltip>
            </SkeletonWrapper>
            <SkeletonWrapper active={loading} length={6}>
              ${details.earnings.sum.toLocaleString()}{' '}
              <span className={Classes.TEXT_MUTED}>
                from {details.earnings.count} job{details.earnings.count === 1 ? '' : 's'}
              </span>
            </SkeletonWrapper>
          </div>
          <div className="flex flex-row margin-bottom-2 flex-wrap">
            <div className="margin-right-3 flex flex-column flex-align-start">
              {details.locality && (
                <SkeletonWrapper active={loading} length={8}>
                  {details.locality}
                </SkeletonWrapper>
              )}

              {details.region && (
                <SkeletonWrapper active={loading} length={10} className={Classes.TEXT_MUTED}>
                  {details.region}
                </SkeletonWrapper>
              )}
            </div>
            <div className="margin-right-3 flex flex-column flex-align-start">
              <SkeletonWrapper active={loading} length={12}>
                Last login:{' '}
                <span className={Classes.TEXT_MUTED}>
                  {user.LastLogin ? moment.duration(user.LastLogin - Date.now()).humanize(true) : '?'}
                </span>
              </SkeletonWrapper>
              <SkeletonWrapper active={loading} length={12}>
                Account age:{' '}
                <span className={Classes.TEXT_MUTED}>{moment.duration(Date.now() - user.created).humanize()}</span>
              </SkeletonWrapper>
            </div>
            <div className="margin-top-05 margin-right-3 flex flex-column flex-align-start">
              <SkeletonWrapper active={loading} length={16}>
                Timezone: <span className={Classes.TEXT_MUTED}>{_.get(user, 'location.timezone', '?')}</span>
              </SkeletonWrapper>
            </div>
          </div>
          <div className="flex flex-row margin-bottom-2 flex-wrap">
            <SkeletonWrapper active={loading} length={10} className="margin-right-2">
              {_.get(user, 'contact.phone.mobile') &&
                /* @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1. */
                `${utils.parsePhoneNumber(user.contact.phone.mobile).format.international} `}

              {_.get(user, 'contact.phone.verification.mobile') && (
                <Icon icon="endorsed" intent="success" className="margin-left-05" />
              )}
            </SkeletonWrapper>
            <SkeletonWrapper active={loading} length={14} className="margin-right-2">
              {user.email}
            </SkeletonWrapper>

            <SkeletonWrapper
              active={loading}
              length={24}
              tag="code"
              className={[Classes.TEXT_MUTED, Classes.TEXT_SMALL, 'margin-top-1'].join('')}
            >
              {user._id}
            </SkeletonWrapper>
          </div>
          <SkeletonWrapper active={loading}>
            {loading && 'LOADING'}

            <div className="flex flex-row margin-bottom-1 flex-wrap flex-align-center">
              {_.get(user, 'meta.feedback_sample.recording_url') && (
                <AudioButton
                  // volumeControl
                  label="Voice Clip:"
                  inline
                  sound={{
                    autoPlay: true,
                    url: user.meta.feedback_sample.recording_url,
                  }}
                  intent="primary"
                  minimal
                  className="margin-bottom-0 margin-right-2"
                />
              )}
              {_.get(user, 'settings.remote.setup_check') && (
                <span className="margin-right-2">
                  <Emoji emoji="📶" label="Network quality" />
                  Network quality &nbsp;&nbsp;
                  {_.get(user, 'settings.remote.network_quality')
                    ? `${_.get(user, 'settings.remote.network_quality').toFixed(1)} / 5`
                    : 'N/A'}
                </span>
              )}
            </div>
          </SkeletonWrapper>
          <section id="billing" className="margin-bottom-1">
            <SkeletonWrapper active={loading}>
              <h4 className="tw-text-lg tw-font-bold">
                Billing information <Emoji emoji="💰" label="Billing" />
              </h4>
              <div className="flex flex-column space-y-1">
                {user?.settings?.billing?.preferred ? (
                  <>
                    <div>
                      Preferred payment method:{' '}
                      <span className={Classes.TEXT_MUTED}>{capitalize(user?.settings?.billing?.preferred)}</span>
                    </div>
                    {user?.settings?.billing.preferred === 'paypal' ? (
                      <>
                        <div>
                          Account name: <span className={Classes.TEXT_MUTED}>{user?.settings?.billing?.paypal}</span>
                        </div>
                        <div>
                          Account verified:{' '}
                          <span>
                            {user?.settings?.billing?.paypal_details?.verification_details?.verified_account ? (
                              <Emoji emoji="✅" />
                            ) : (
                              <Emoji emoji="❌" />
                            )}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div>
                        Account name:{' '}
                        <span className={Classes.TEXT_MUTED}>{capitalize(user?.settings?.billing?.giftpay)}</span>
                      </div>
                    )}
                  </>
                ) : (
                  <p>No payment information linked</p>
                )}
              </div>
            </SkeletonWrapper>
          </section>
          <section id="devices">
            <SkeletonWrapper active={loading}>
              <h4 className="tw-text-lg tw-font-bold">
                Devices <Emoji emoji="📱" label="Device" />
              </h4>
              <div>
                {user?.devices?.length ? (
                  <table className="tw-table-auto tw-border-spacing-4 tw-text-center">
                    <thead className="tw-bg-slate-600">
                      <tr>
                        <th>OS</th>
                        <th>App version</th>
                        <th>Last login</th>
                        <th className="tw-p-2">Notifications</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user?.devices.map((device: any) => (
                        <tr key={device.id}>
                          <td>{device.os}</td>
                          <td>{device.app_version}</td>
                          <td>{device.last_login ? moment(device.last_login).fromNow() : 'Never'}</td>
                          <td>{device.token ? <Emoji emoji="✅" /> : <Emoji emoji="❌" />}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No devices found</p>
                )}
              </div>
            </SkeletonWrapper>
          </section>
        </Collapse>
      </Card>
    );
  }

  renderInfoSection({ user, loading, refetch }: any) {
    switch (this.state.tabs_info) {
      case 'Update Profile':
        return (
          <Mutation<Admin_UpdateUserMutation, Admin_UpdateUserMutationVariables> mutation={updateUser}>
            {(mutate, result) => (
              <UpdateProfile
                user={user}
                loading={loading || result.loading}
                key={user.updated}
                error={result.error}
                onSave={(saveUser: Admin_UpdateUserMutationVariables['user']) => {
                  mutate({
                    variables: { user: saveUser, _id: this.props._user_id || '' },
                    refetchQueries: ['admin_getParticipantDetails'],
                  })
                    .then(() => {
                      this.props.context.newToast({ message: 'Profile updated', intent: 'success' });
                      refetch();
                    })
                    .catch(() => {
                      this.props.context.newToast({ message: 'Profile update failed', intent: 'danger' });
                    });
                }}
              />
            )}
          </Mutation>
        );
      case 'Options':
        return (
          <InfoOptions
            key={user._id}
            user={user}
            loading={loading}
            participantAppNavigate={this.participantAppNavigate}
            context={this.props.context}
            refetch={refetch}
          />
        );
      case 'Admin Messages':
        return <AdminMessages key={user._id} user={user} />;
      default:
        return <pre className="margin-top-1 margin-left-3">{this.state.tabs_info}</pre>;
    }
  }
  renderTablesSection({ user }: any) {
    switch (this.state.tabs_table) {
      case 'Bookings':
        return (
          <BookingsTable
            key={user._id}
            user={user}
            timezone={this.state.timezone}
            participantAppLinkClick={this.participantAppLinkClick}
          />
        );
      case 'Payments':
        return (
          <PaymentsTable
            key={user._id}
            user={user}
            timezone={this.state.timezone}
            participantAppLinkClick={this.participantAppLinkClick}
          />
        );
      case 'Screener analysis':
        return <ParticipantScreenerAnalysis key={user._id} userId={user?._id} />;
      default:
        return <pre>{this.state.tabs_table}</pre>;
    }
  }

  renderPageTitle(user: any) {
    const userName = [_.get(user, 'meta.identity.firstname', ''), _.get(user, 'meta.identity.lastname', '')]
      .join(' ')
      .replace(/\s+/g, ' ')
      .replace(/^\s|\s$/g, '');

    if (userName) return userName;
    if (user.email) return user.email;
    return '(Participant)';
  }

  render() {
    return (
      <div id="user-participant-details" key={this.props._user_id}>
        <Query<Admin_GetParticipantDetailsQuery>
          query={getParticipantDetails}
          variables={{ _id: this.props._user_id }}
          fetch-policy="cache-and-network"
          notifyOnNetworkStatusChange
          onCompleted={this.userQueryCompletedCallback}
        >
          {({ loading, error, data, refetch }) => {
            if (error) return errorPage({ error });

            const user = _.get(data, 'userByID', { _id: this.props._user_id });
            if (!user && !loading) return errorPage({ title: 'User not found / invlalid', error: this.props._user_id });

            return (
              <div className="contents">
                <div className="column info">
                  {this.renderInfoCard({ user, loading })}

                  <AskableNavbar items={this.parseTabs('info')} className="padding-left-3" />
                  <div className="info-content">{this.renderInfoSection({ user, loading, refetch })}</div>
                </div>
                <div className="column tables">
                  <AskableNavbar items={this.parseTabs('table')} className="padding-left-0">
                    {this.state.timezone && (
                      <NavbarGroup align={Alignment.RIGHT}>
                        <Tag minimal>
                          <strong>{this.state.timezone}</strong> timezone
                        </Tag>
                        <Button small minimal text="Switch" onClick={this.toggleTimezone} />
                      </NavbarGroup>
                    )}
                  </AskableNavbar>
                  <div className="tables-content">{this.renderTablesSection({ user })}</div>
                </div>
                <div className="column app">
                  <Mutation mutation={adminLoginAsUser} variables={{ _id: user?._id }}>
                    {(mutate: any, result: any) => (
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ path: any; user: any; loginAsUser: { mutat... Remove this comment to see the full error message
                      <AppFrame path={this.state.participantAppPath} user={user} loginAsUser={{ mutate, result }} />
                    )}
                  </Mutation>
                </div>

                {!loading && <PageTitle title={this.renderPageTitle(user)} />}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

const Wrapper: FC<any> = props => {
  const params = useParams();

  return <ParticipantDetails {...props} _user_id={params?._user_id} />;
};

export default AppConsumer(Wrapper);
